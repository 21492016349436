<template>
  <div class="container-xl header-large bg-grey">
    <div>
      <div class="img-register-container">
        <img
          src="/img/register.png"
          alt="Register Button"
          class="w-100 d-block fade-in-up-register"
          v-scroll-to="{ el: '#register', offset: -100 }"
          role="button"
        />
      </div>
      <section class="section-height"></section>
      <!-- <object
        data="/img/animated_seal.svg"
        type="image/svg+xml"
        class="seal"
      ></object> -->

      <b-row class="text-container">
        <b-col>
          <!-- Live Life Text -->
          <div
            class="large-text fade-in-up-element overflow-hidden position-relative freight-big-pro-book mb-4"
            style="z-index: 10"
          >
            All
          </div>
          <!-- Above -->
          <div
            class="above-text fade-in-up-element large-text overflow-hidden freight-big-pro-book-italic mb-4"
          >
            <span class="">You Need</span>
            <div class="line-container">
              <div class="live-line mask1"></div>
            </div>
          </div>

          <!-- It All & Register Button -->
          <div
            class="xl-text fade-in-up-element mb-5 zIndex-10 text-uppercase freight-big-pro-book"
          >
            IS YONGE
          </div>
          <!-- <div class="fade-in-up-element medium-text font-italic d-block text-brown mb-2">
            Where Yorkville meets Forest Hill
          </div> -->
          <!-- Sub Text & Image -->
          <div
            class="live-life-text fade-in-up-element sub-text zIndex-10 lato-regular mb-2"
          >
          Live life... at the top of the hill above the most coveted neighbourhoods in Toronto; where the journey home gravitates through the pathways of the grand and stately homes in Forest Hill, Yorkville, South Hill and Summerhill. Where residents' social lives are naturally woven into the fabric of the well travelled and the well connected. At the top of the hill on one of Toronto’s most celebrated streets, above the city’s ever-changing lifestyle, above the standards of elegance and taste, where timid aspirations never reach, with views of an iconic skyline, admired worldwide, and above the bounds of possibility that couldn’t exist anywhere else.
            <div class="d-block pt-4">
            <span class="freight-big-pro-book medium-text"
              >JUNIOR 1 BED</span>&nbsp;&nbsp;<span class="freight-big-pro-book small-text">FROM</span>&nbsp;&nbsp;<span class="freight-big-pro-book medium-text">$549,900</span
            ><br/>
            <span class="freight-big-pro-book medium-text"
              >1 BED</span>&nbsp;&nbsp;<span class="freight-big-pro-book small-text">FROM</span>&nbsp;&nbsp;<span class="freight-big-pro-book medium-text">$679,900</span
            ><br/>
            <span class="freight-big-pro-book medium-text"
              >JUNIOR 2 BED</span>&nbsp;&nbsp;<span class="freight-big-pro-book small-text">FROM</span>&nbsp;&nbsp;<span class="freight-big-pro-book medium-text">$799,900</span
            >
            </div>
            <span class="freight-big-pro-book-italic-bold small-text d-block pt-3"
              >This Is The Hill. At Yonge & St. Clair.</span
            >
          </div>
        </b-col>
      </b-row>

      <!-- Sub Text & Image -->
      <b-row class="">
        <b-col class="couple-wrapper relative">
          <div
            class="fade-in-up-register d-couple-container d-lg-block d-none"
          >
            <img
              src="/img/buildings-corner.jpg"
              alt="Couple walking on cobble stone road"
              class="img-fluid h-100"
            />
          </div>
          <div
            class="mb-couple-container d-block d-block d-lg-none pt-4"
          >
            <img
              src="/img/buildings-corner.jpg"
              alt="Couple walking on cobble stone road"
              class="w-100 max-w-380 d-block img-fluid"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {
      this.lineReveal();
      this.registerReveal();
    }, 500);
  },
  methods: {
    lineReveal() {
      this.moveLine = gsap;

      var scrollTimeline = this.moveLine
        .timeline({ paused: true })
        .set(".live-line", { transformOrigin: "100% 50%" })
        .from(".mask1", 2.2, { width: "0px" });

      this.moveLineTrigger = ScrollTrigger.create({
        trigger: ".text-container",
        start: "center bottom",
        stagger: 0.2,
        animation: scrollTimeline,
      });
    },
    registerReveal() {
      let fadeInUpRegister = document.querySelectorAll(".fade-in-up-register");

      fadeInUpRegister.forEach((container) => {
        let fadeInUpRegister_live = this.fadeInUpRegister;
        fadeInUpRegister_live = gsap.timeline({
          scrollTrigger: {
            trigger: ".live-life-text",
            start: "center bottom",
            stagger: 0.2,
          },
        });

        fadeInUpRegister_live.set(container, { autoAlpha: 1, zIndex: 1000 });
        fadeInUpRegister_live.from(container, 1, {
          opacity: 0,
          y: 100,
          ease: "power2.out",
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/variables";
.header-large {
  padding: 0px 0px 100px 100px;
  position: relative;
  .section-height {
    height: 100px;
  }
  .img-register-container {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0%);
    width: 100%;
    max-width: 200px;
    padding: 0px 0px 0px 0px;
  }
  .seal {
    max-width: 150px;
    position: absolute;
    right: 20rem;
    top: 8.5rem;
    z-index: 99999;
  }
  .large-text{
    width: 600px;
  }
  .above-text {
    position: relative;
    width: 600px;
    .line-container {
      height: 10px;
      // width: 100%;
      position: absolute;
      bottom: 0%;
      left: 60%;
      z-index: 9999;
      overflow: hidden;
      .live-line {
        height: 2px;
        background-color: $m-blue;
        overflow: hidden;
        width: 160px;
      }
      @media screen and (max-width: 950px) {
        left: 37%;
        .live-line {
          width: 100px;
      }
      }
      @media screen and (max-width: 830px) {
        left: 32%;
      }
      @media screen and (max-width: 500px) {
        left: 30%;
      }
    }
  }

  .couple-wrapper {
    position: relative;
    .d-couple-container {
      position: absolute;
      right: 0;
      bottom: 0rem;
      @media screen and (max-width: 750px) {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    padding: 0px 0px 100px 45px;
  }
  @media screen and (max-width: 760px) {
    .seal {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0px 25px 100px 25px;
    .section-height {
      height: 40px;
    }
    .seal {
      // top: 29rem;
      // right: 14.5rem;
      // display: unset;
      display: none;
    }
  }
}
</style>
